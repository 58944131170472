

/*--------------------------------------------------------------------- File Name: style.css ---------------------------------------------------------------------*/


/*--------------------------------------------------------------------- import Fonts ---------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Baloo+Chettan&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900|Righteous&display=swap');

/*****---------------------------------------- 1) font-family: 'Rajdhani', sans-serif;
 2) font-family: 'Poppins', sans-serif;
 ----------------------------------------*****/


/*--------------------------------------------------------------------- import Files ---------------------------------------------------------------------*/

@import url(animate.min.css);
@import url(normalize.css);
@import url(icomoon.css);
@import url(font-awesome.min.css);
@import url(meanmenu.css);
@import url(owl.carousel.min.css);
@import url(slick.css);
@import url(jquery.fancybox.min.css);
@import url(jquery-ui.css);
@import url(nice-select.css);

/*--------------------------------------------------------------------- skeleton ---------------------------------------------------------------------*/

* {
     box-sizing: border-box !important;
}

html {
     scroll-behavior: smooth;
}

body {
     color: #666666;
     font-size: 14px;
     font-family: 'Lato', sans-serif;
     line-height: 1.80857;
     font-weight: normal;
}

a {
     color: #1f1f1f;
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
     letter-spacing: 0;
     font-weight: normal;
     position: relative;
     padding: 0 0 10px 0;
     font-weight: normal;
     line-height: normal;
     color: #111111;
     margin: 0
}

h1 {
     font-size: 24px;
     font-family: 'Baloo Chettan', cursive;
}

h2 {
     font-size: 22px;
     font-family: 'Baloo Chettan', cursive;
}

h3 {
     font-size: 18px;
     font-family: 'Baloo Chettan', cursive;
}

h4 {
     font-size: 16px
}

h5 {
     font-size: 14px
}

h6 {
     font-size: 13px
}

*,
*::after,
*::before {
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
     color: #212121;
     text-decoration: none!important;
     opacity: 1
}

button:focus {
     outline: none;
}

ul,
li,
ol {
     margin: 0px;
     padding: 0px;
     list-style: none;
}

p {
     margin: 0px;
     font-weight: 300;
     font-size: 15px;
     line-height: 24px;
}

a {
     color: #222222;
     text-decoration: none;
     outline: none !important;
}

a,
.btn {
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}

img {
     max-width: 100%;
     height: auto;
}

 :focus {
     outline: 0;
}

.btn-custom {
     margin-top: 20px;
     background-color: transparent !important;
     border: 2px solid #ddd;
     padding: 12px 40px;
     font-size: 16px;
}

.lead {
     font-size: 18px;
     line-height: 30px;
     color: #767676;
     margin: 0;
     padding: 0;
}

.form-control:focus {
     border-color: #ffffff !important;
     box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .25);
}

.navbar-form input {
     border: none !important;
}

.badge {
     font-weight: 500;
}

blockquote {
     margin: 20px 0 20px;
     padding: 30px;
}

button {
     border: 0;
     margin: 0;
     padding: 0;
     cursor: pointer;
}

.full {
     float: left;
     width: 100%;
}

.layout_padding {
     padding-top: 90px;
     padding-bottom: 90px;
}

.layout_padding_2 {
     padding-top: 75px;
     padding-bottom: 75px;
}

.light_silver {
     background: #f9f9f9;
}

.theme_bg {
     background: #38c8a8;
}

.margin_top_30 {
     margin-top: 30px !important;
}

.full {
     width: 100%;
     float: left;
     margin: 0;
     padding: 0;
}


/**-- heading section --**/


/*---------------------------- preloader area ----------------------------*/

.main-layout {
     background-repeat: no-repeat;
     background: url(../images/body_bg.png);
}

.loader_bg {
     position: fixed;
     z-index: 9999999;
     background: #fff;
     width: 100%;
     height: 100%;
}

.loader {
     height: 100%;
     width: 100%;
     position: absolute;
     left: 0;
     top: 0;
     display: flex;
     justify-content: center;
     align-items: center;
}

.loader img {
     width: 280px;
}


/*-- navigation--*/

.navigation.navbar {
     padding-top: 12px;
}

.navigation.navbar-dark .navbar-nav .nav-link {
     padding: 0 25px;
     color: #fff;
     font-size: 16px;
     line-height: 20px;
     text-transform: uppercase;
}

.navigation.navbar-dark .navbar-nav .nav-link:focus,
.navigation.navbar-dark .navbar-nav .nav-link:hover {
     color: #000;
}

.navigation.navbar-dark .navbar-nav .active>.nav-link,
.navigation.navbar-dark .navbar-nav .nav-link.active,
.navigation.navbar-dark .navbar-nav .nav-link.show,
.navigation.navbar-dark .navbar-nav .show>.nav-link {
     color: #fdd430;
}


/*-- header area --*/


/*--------------------------------------------------------------------- top banner area ---------------------------------------------------------------------*/


/*--------------------------------------------------------------------- layout new css ---------------------------------------------------------------------*/

.head_top {
     background: url(../images/banner.png);
     background-size: 100% 100%;
     background-repeat: no-repeat;
     background-position: center;
}

.header {
     width: 100%;
     background: transparent;
     padding: 30px 30px;
}

.logo a {
     font-size: 40px;
     font-weight: bold;
     text-transform: uppercase;
     color: #fff;
     line-height: 40px;
}

.banner_main {
     padding: 90px 0 190px 0;
}

.text-bg {
     text-align: left;
     max-width: 561px;
     width: 100%;
     float: right;
     padding-right: 141px;
}

.text-bg h1 {
     color: #fff;
     font-size: 48px;
     line-height: 65px;
     font-weight: bold;
}

.text-bg p {
     color: #fff;
     font-size: 17px;
     line-height: 28px;
     padding: 40px 0;
}

.text-bg a {
     font-size: 17px;
     background-color: #fff;
     color: #0891f8;
     padding: 13px 0px;
     width: 100%;
     max-width: 190px;
     text-align: center;
     display: inline-block;
     transition: ease-in all 0.5s;
     border-radius: 10px;
}

.text-bg a:hover {
     background-color: #000;
     color: #fff;
     transition: ease-in all 0.5s;
     border-radius: 26px;
}

.text-img {
     padding-top: 100px;
}

.text-img figure {
     margin: 0px;
}

.text-img figure img {
     width: 100%;
}

.titlepage {
     text-align: center;
     padding-bottom: 60px;
}

.titlepage h2 {
     font-size: 40px;
     color: #3e3e3e;
     line-height: 35px;
     font-weight: bold;
     padding: 0;
     padding-bottom: 20px;
}

.d_flex {
     display: flex;
     align-items: center;
     flex-wrap: wrap;
}


/** business section **/

.business {
     padding-top: 90px;
}

.business .titlepage span {
     color: #0891f8;
     font-size: 30px;
     font-family: 'Baloo Chettan', cursive;
     text-transform: uppercase;
     line-height: 37px;
     padding-bottom: 10px;
     display: inline-block;
}

.business .titlepage h2 {
     color: #272f43;
     font-weight: normal;
}

.business .business_box {
     text-align: center;
}

.business .business_box figure {
     margin: 0;
}

.business .business_box figure img {
     width: 100%;
}

.business .business_box p {
     color: #000;
     font-size: 17px;
     line-height: 28px;
     padding: 35px 0px;
}

.business .business_box .read_more {
     background: #252525;
     color: #fff;
}

.business .business_box .read_more:hover {
     background: #0891f8;
}


/** end business section **/


/** projects section **/

.projects {
     padding-top: 90px;
     position: relative;
}

.projects::after {
     position: absolute;
     content: "";
     width: 320px;
     height: 542px;
     right: 0;
     /* background: url(../images/midil.png); */
     top: -110px;
}

.projects .titlepage span {
     color: #0891f8;
     font-size: 30px;
     font-family: 'Baloo Chettan', cursive;
     text-transform: uppercase;
     line-height: 37px;
     padding-bottom: 10px;
     display: inline-block;
}

.projects .titlepage h2 {
     color: #272f43;
     font-weight: normal;
}

.projects .projects_box {
     text-align: center;
}

.projects .projects_box figure {
     margin: 0;
}

.projects .projects_box figure img {
     width: 100%;
}

.projects .projects_box p {
     color: #000;
     font-size: 17px;
     line-height: 28px;
     padding: 60px 0px 35px 0px;
}

.projects .projects_box .read_more {
     background: #252525;
     color: #fff;
}

.projects .projects_box .read_more:hover {
     background: #0891f8;
}


/** end projects section **/


/** Testimonial section **/

.Testimonial {
     background: url(../images/test.png);
     background-repeat: no-repeat;
     margin-top: 90px;
     padding: 90px 0px;
     background-position: center;
     background-size: 100% 100%;
}

.Testimonial2 {
     background: url(../images/test2.png);
     background-repeat: no-repeat;
     margin-top: 90px;
     padding: 90px 0px;
     background-position: center;
     background-size: 100% 100%;
}

.Testimonial .titlepage h2 {
     color: #fff;
     margin-bottom: 0px;
     padding-bottom: 0px;
}

.Testimonial .titlepage span {
     color: #fff;
}

.Testimonial_box i {
     border: #e6e1e1 solid 12px;
     width: 150px;
     height: 150px;
     display: flex;
     align-items: center;
     justify-content: center;
     margin: 0 auto;
     border-radius: 210px;
     cursor: pointer;
     background: #e6e1e1;
}

/* .Testimonial_box i::after {
     position: absolute;
     content: "";
     width: 50px;
     height: 50px;
     bottom: 40px;
     right: 63px;
     display: inline-block;
     border: #ddd solid 6px;
     border-radius: 30px;
} */

.Testimonial_box {
     text-align: left;
}

.Testimonial_box h4 {
     color: #fff;
     text-transform: uppercase;
     font-size: 20px;
     line-height: 20px;
     font-weight: 400;
     padding: 0;
}

.Testimonial_box p {
     color: #fff;
     font-size: 17px;
     line-height: 28px;
     padding: 25px 0;
     padding-right: 200px;
}


/** end Testimonial section **/


/** contact section **/

.padd_leri0 {
     padding-left: 0;
     padding-right: 0;
}

.contact .titlepage span {
     font-size: 17px;
     line-height: 28px;
}

.contact {
     padding-top: 90px;
}

.main_form {
     padding: 50px 50px;
     border: #008df3 solid 1px;
     border-radius: 40px;
     margin: 0px 80px;
}

.contact .main_form .form_contril {
     border: #008df3 solid 1px;
     margin-bottom: 20px;
     width: 100%;
     height: 45px;
     background: #fff;
     color: #008df3;
     font-size: 17px;
     border-top: 0;
     border-right: 0;
     border-left: 0;
}

.contact .main_form .textarea {
     border: #008df3 solid 1px;
     margin-bottom: 20px;
     width: 100%;
     height: 60px;
     background: #fff;
     color: #008df3;
     font-size: 17px;
     border-top: 0;
     border-right: 0;
     border-left: 0;
}

.contact .main_form .send_btn {
     font-size: 17px;
     transition: ease-in all 0.5s;
     background-color: #000;
     color: #fff;
     padding: 13px 0px;
     margin: 0 auto;
     display: block;
     margin-top: 30px;
     width: 100%;
     max-width: 190px;
}

.contact .main_form .send_btn:hover {
     background-color: #008df3;
     transition: ease-in all 0.5s;
     color: #fff;
     border-radius: 26px;
}

#contact *::placeholder {
     color: #008df3;
     opacity: 1;
}

.current {
     color: #fff;
     margin-top: -4px;
     position: absolute;
}

.con_img figure {
     margin: 0;
}

.con_img figure img {
     width: 100%;
}


/** end contact section **/

.read_more {
     font-size: 17px;
     background-color: #fff;
     color: #000;
     padding: 13px 0px;
     width: 100%;
     max-width: 190px;
     text-align: center;
     display: inline-block;
     transition: ease-in all 0.5s;
     border-radius: 10px;
}

.read_more:hover {
     background: #000;
     color: #fff;
     border-radius: 26px;
     transition: ease-in all 0.5s;
}


/** footer **/

.multi {
     color: #008df3;
}

.footer {
     background: #fff;
}

.cont {
     text-align: center;
     margin-top: 80px;
}

.cont h3 {
     font-family: 'Righteous', cursive;
     font-size: 30px;
     line-height: 45px;
     color: #23262d;
     font-weight: bold;
     padding-bottom: 30px;
}

.cont span {
     color: #fff;
     font-size: 30px;
     line-height: 30px;
     padding: 30px 0px 30px 0px;
     display: inline-block;
     font-weight: bold;
}

.cont p {
     color: #fff;
     font-size: 17px;
     line-height: 30px;
     padding: 0 130px;
     padding-bottom: 60px;
}

ul.social_icon {
     text-align: center;
}

ul.social_icon li {
     display: inline-block;
     margin: 0 3px;
}

ul.social_icon li a {
     background: #fff;
     box-shadow: #ddd 0px 0px 10px 0px;
     font-size: 22px;
     padding-top: 5px;
     border-radius: 50px;
     width: 50px;
     height: 50px;
     display: inline-block;
     color: #008df3;
}

ul.social_icon li a:hover {
     background: #23262d;
}

.copyright {
     padding: 20px 0px;
     background: #0891f8;
     margin-top: 50px;
     position: relative;
}

.copyright::after {
     content: "";
     position: absolute;
     bottom: 61px;
     background: url(../images/bottom.png);
     width: 290px;
     height: 292px;
     right: 0;
}

.copyright p {
     color: #fff;
     font-size: 18px;
     line-height: 22px;
     text-align: center;
}

.copyright a {
     color: #fff;
}

.copyright a:hover {
     color: #23262d;
}


/** end footer **/

